<template>
  <div class="admin-menubar-marquee-parent">
    <div class="admin-menubar-marquee">
      <div class="marquee-text">
        The marquee element itself is where the magic happens. white-space: nowrap makes sure our text stays in a single line. The absolute position is necessary so the element gets its full width, even if it’s wider than the parent admin-menubar-marquee. Otherwise the animation, which is relative to the element size, would not go all the way through. Same goes for min-width: 100%.
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    components: {},
    data() {
      return {};
    },
    computed: {
      ...mapGetters({
        // viewerCount: "twitch/getViewerCount",
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .admin-menubar-marquee-parent {
    display: flex;
    align-items: center;
    height: rem-calc(50);
    // background: aqua;
  }

  // https://reneroth.xyz/marquees-in-css/
  .admin-menubar-marquee {
    position: relative;
    width: 100%;
    // overflow: hidden;
  }

  .admin-menubar-marquee:hover .marquee {
    animation-play-state: paused;
  }

  .admin-menubar-marquee::before {
    content: "\00a0";
  }

  .marquee-text {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    white-space: nowrap;
    font-family: "Avenir Next";
    font-size: rem-calc(26);
    color: #eee;
    animation: marquee 60s linear infinite;
    will-change: transform;
  }

  @keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
</style>
